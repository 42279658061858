/* Global imports */
import PropTypes from "prop-types";
import React from "react";

/* Local imports */

/* Component definition */
const Checkbox = ({ className, text, value, checked, onToggle, disabled, name }) => {
  return (
    <label
      className={`checkbox ${
        disabled ? "checkbox--disabled" : ""
      } ${className}`}
    >
      {text}
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onToggle}
        disabled={disabled}
      />
      <span className="checkmark"></span>
    </label>
  );
};

/* PropTypes */
Checkbox.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string
};
Checkbox.defaultProps = {
  className: "",
  checked: false,
  disabled: false,
  name: ""
};

/* Local utility functions */

/* Styles */

export default Checkbox;
