/* Global imports */
import { faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faEdit,
  faGlobe,
  faSearch,
  faSortDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate } from "gatsby";
import React from "react";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { YOUTUBE_CHANNEL_URL } from "../constants";
import Link, { getLocalizedLink } from "../helper/link";
import { getValue } from "../helper/translation";
import useToggle from "../hooks/useToggle";
/* Local imports */
import Button from "./button";
import InputWithButton from "./InputWithButton";

const MobileNav = ({ siteSettings }) => {
  const { microcopy } = siteSettings;

  const isSmall = useMediaQuery({ minWidth: 576 });
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [languageOpen, toggleLanguageOpen] = useToggle(false);
  const [show, setShow] = React.useState(false);

  const defaultState = {};
  siteSettings.navigation.forEach((navigationItem) => {
    defaultState[navigationItem.name] = false;
  });
  const [collapsed, setCollapsed] = React.useState(defaultState);

  const getLocalizedCurrentLink = (locale, currentPath) => {
    return locale === "de-DE"
      ? currentPath.replace("/en", "")
      : "/en" + currentPath;
  };

  const switchLanguage = () => {
    navigate(
      getLocalizedCurrentLink(
        siteSettings.node_locale === "de-DE" ? "en" : "de-DE",
        window.location.pathname + window.location.search
      )
    );
  };

  const goToSearchPage = () => {
    navigate(
      getLocalizedLink(`/search?q=${searchTerm}`, siteSettings.node_locale)
    );

    // in the case we are already in the search page
    setSearchTerm("");
    toggleSearchOpen();
  };

  const toggleSearchOpen = () => {
    if (!searchOpen) {
      setShow(false);
    }
    setSearchOpen(!searchOpen);
  };

  return (
    <nav id="nav" className={`mobileNav ${show ? "mobileNav--open" : ""}`}>
      <div className="navbar d-flex justify-content-between align-items-center">
        <Link to={getLocalizedLink("/", siteSettings.node_locale)}>
          <img
            src={siteSettings.logoMobile.file.url}
            alt="Logo"
            className="logo"
          />
        </Link>
        <div className="d-flex align-items-center">
          {isSmall && (
            <Button
              text={
                <>
                  <img
                    src="/pbf_signet@1x.svg"
                    alt="PBF logo"
                    style={{ marginRight: "6px" }}
                    className="pbf-logo"
                  />
                  Peacebuilding Forum
                </>
              }
              className="small"
              link="https://www.frient-peacebuilding-forum.de/"
              target="_blank"
            />
          )}
          <FontAwesomeIcon
            icon={faSearch}
            className="icon"
            onClick={toggleSearchOpen}
          />
          {!show && (
            <FontAwesomeIcon
              icon={faBars}
              className="icon"
              onClick={() => setShow(true)}
            />
          )}
          {show && (
            <FontAwesomeIcon
              icon={faTimes}
              className="icon"
              onClick={() => setShow(false)}
            />
          )}
        </div>
      </div>
      <div className={`menu ${show ? "menu--open" : ""}`}>
        <div>
          <div className="menu-items">
            {siteSettings.navigation.map((navigationItem) => {
              return navigationItem.childPages &&
                navigationItem.childPages.length > 0 ? (
                <div
                  key={navigationItem.slug}
                  className={`menu-item ${
                    collapsed[navigationItem.localizedPage.name]
                      ? "menu-item--open"
                      : ""
                  }`}
                >
                  <div
                    className="d-flex justify-content-between"
                    aria-controls={navigationItem.slug}
                    aria-expanded={collapsed[navigationItem.localizedPage.name]}
                  >
                    <Link
                      to={getLocalizedLink(
                        `/${navigationItem.slug}`,
                        siteSettings.node_locale
                      )}
                    >
                      {navigationItem.localizedPage.name}
                    </Link>
                    <FontAwesomeIcon
                      icon={faSortDown}
                      onClick={() =>
                        setCollapsed({
                          [navigationItem.localizedPage.name]:
                            !collapsed[navigationItem.localizedPage.name],
                        })
                      }
                    />
                  </div>
                  <Collapse
                    in={collapsed[navigationItem.localizedPage.name]}
                    className="sub-items"
                  >
                    <div>
                      <ul id={navigationItem.slug}>
                        {navigationItem.childPages.map((subNavigationItem) => (
                          <li key={subNavigationItem.slug}>
                            <Link
                              to={getLocalizedLink(
                                `/${navigationItem.slug}/${subNavigationItem.slug}`,
                                siteSettings.node_locale
                              )}
                            >
                              {subNavigationItem.localizedPage.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Collapse>
                </div>
              ) : (
                <div className="menu-item" key={navigationItem.slug}>
                  <div>
                    <Link
                      to={getLocalizedLink(
                        `/${navigationItem.slug}`,
                        siteSettings.node_locale
                      )}
                    >
                      {navigationItem.localizedPage.name}
                    </Link>
                  </div>
                </div>
              );
            })}
            <div className="menu-item">
              <div>
                <Link
                  to={getLocalizedLink(
                    `/artikel?type=blog`,
                    siteSettings.node_locale
                  )}
                >
                  Blog
                </Link>
              </div>
            </div>
            <div className="menu-item">
              <div>
                <Link
                  to={getLocalizedLink(`/newsletter`, siteSettings.node_locale)}
                >
                  Newsletter
                </Link>
              </div>
            </div>
          </div>
          {!isSmall && (
            <Button
              text={
                <>
                  <img
                    src="/pbf_signet@1x.svg"
                    alt="PBF logo"
                    style={{ marginRight: "6px" }}
                    className="pbf-logo"
                  />
                  Peacebuilding Forum
                </>
              }
              className="fit small"
              link="https://www.frient-peacebuilding-forum.de/"
              target="_blank"
            />
          )}
          <div className="d-flex justify-content-between actions">
            <div>
              <Link
                to={getLocalizedLink(
                  `/artikel?type=blog`,
                  siteSettings.node_locale
                )}
                className="icon"
              >
                <FontAwesomeIcon icon={faEdit} className="icon" />
              </Link>
              <Link
                to={{
                  url: YOUTUBE_CHANNEL_URL,
                  linkText: "",
                }}
                className="icon"
              >
                <FontAwesomeIcon icon={faYoutube} className="icon" />
              </Link>
              <Link
                to={{ url: "https://twitter.com/frient_news", linkText: "" }}
                className="icon"
              >
                <FontAwesomeIcon icon={faTwitter} className="icon" />
              </Link>
            </div>
            <div
              className={`d-flex align-items-center language ${
                languageOpen ? "language--open" : ""
              }`}
              onClick={toggleLanguageOpen}
              role="button"
              tabIndex={0}
              onKeyDown={toggleLanguageOpen}
            >
              <FontAwesomeIcon icon={faGlobe} className="icon icon--white" />
              <span>{siteSettings.node_locale === "de-DE" ? "DE" : "EN"}</span>
            </div>
            <div
              className="d-flex align-items-center language language--secondary"
              onClick={switchLanguage}
              role="button"
              tabIndex={0}
              onKeyDown={switchLanguage}
            >
              <FontAwesomeIcon icon={faGlobe} className="icon icon--white" />
              <span>{siteSettings.node_locale === "de-DE" ? "EN" : "DE"}</span>
            </div>
          </div>
        </div>
      </div>
      <Collapse in={searchOpen} className="search">
        <div>
          <Container fluid>
            <Row>
              <Col>
                <h4 className="text-center">
                  {getValue("search.question", microcopy)}
                </h4>
                <InputWithButton
                  buttonText={<FontAwesomeIcon icon={faSearch} />}
                  placeholder={getValue("placeholders.searchTerm", microcopy)}
                  onButtonClick={goToSearchPage}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </Collapse>
    </nav>
  );
};

/* PropTypes */
MobileNav.propTypes = {};
MobileNav.defaultProps = {};

/* Local utility functions */

/* Styles */

export default MobileNav;
