/* Global imports */
import {
  faEnvelope,
  faPenSquare,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, navigate } from "gatsby";
import Image from "gatsby-image";
import React, { useEffect } from "react";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import { getLocalizedLink } from "../helper/link";
import { getValue } from "../helper/translation";
import useToggle from "../hooks/useToggle";
/* Local imports */
import Button from "./button";
import InputWithButton from "./InputWithButton";

/* Component definition */

const DesktopNav = ({ siteSettings }) => {
  const { microcopy } = siteSettings;
  const [hovered, setHovered] = React.useState(null);
  const [sticky, setSticky] = React.useState(false);
  const [searchOpen, toggleSearchOpen] = useToggle(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const stickyRef = React.useRef();
  const subMenuRef = React.useRef();

  const handleScroll = () => {
    window.scrollY > stickyRef.current.getBoundingClientRect().bottom
      ? setSticky(true)
      : setSticky(false);
    Array.from(document.getElementsByClassName("sub-menu")).forEach(
      el => (el.style.top = `${130 - window.scrollY}px`)
    );
  };

  const getLocalizedCurrentLink = (locale, currentPath) => {
    return locale === "de-DE"
      ? currentPath.replace("/en", "")
      : "/en" + currentPath;
  };

  const isPageActive = page => {
    const currentPathParts = window.location.pathname.split("/");
    const firstLevelNavigation =
      siteSettings.node_locale === "en"
        ? currentPathParts[2].replace("/", "")
        : currentPathParts[1].replace("/", "");

    return page.slug === firstLevelNavigation;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (searchOpen) {
      closeSubMenu();
    }
  }, [searchOpen]);

  const goToSearchPage = () => {
    navigate(
      getLocalizedLink(`/search?q=${searchTerm}`, siteSettings.node_locale)
    );

    // in the case we are already in the search page
    setSearchTerm("");
    toggleSearchOpen();
  };

  const closeSubMenu = () => {
    setHovered(false);
  };

  const renderSubMenu = (navigationItem, index, sticky) => (
    <div className="sub-menu" ref={sticky ? null : subMenuRef}>
      <Collapse in={hovered === index}>
        <div>
          <Container fluid className="sub-menu__content">
            <Row>
              <Col className="sub-menu__links" md={3}>
                <span className="sub-menu-links__overview">
                  {getValue("labels.overview", microcopy)}
                </span>

                <div>
                  {navigationItem.childPages &&
                    navigationItem.childPages.map(item => (
                      <Link
                        key={item.slug}
                        to={getLocalizedLink(
                          `/${navigationItem.slug}/${item.slug}`,
                          siteSettings.node_locale
                        )}
                        className="sub-menu-links__link"
                      >
                        {item.localizedPage.name}
                      </Link>
                    ))}
                </div>
              </Col>
              <Col md={{ span: 4, offset: 1 }}>
                <p>
                  {navigationItem.localizedPage.shortDescription &&
                    navigationItem.localizedPage.shortDescription.content[0]
                      .content[0].value}
                </p>
                <Button
                  className="sub-menu-see-more"
                  type="quaternary"
                  text={getValue("actions.seeMore", microcopy)}
                  link={getLocalizedLink(
                    `/${navigationItem.slug}`,
                    siteSettings.node_locale
                  )}
                />
              </Col>
              <Col md={4}>
                {navigationItem.localizedPage.image &&
                  navigationItem.localizedPage.image.imageLandscape.fluid && (
                    <Image
                      fluid={
                        navigationItem.localizedPage.image &&
                        navigationItem.localizedPage.image.imageLandscape.fluid
                      }
                      style={{ width: "100%", height: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
                    />
                  )}
              </Col>
            </Row>
          </Container>
        </div>
      </Collapse>
    </div>
  );

  return (
    <nav
      id="nav"
      className={`desktopNav d-flex align-items-center ${
        sticky ? "desktopNav--sticky" : ""
      }`}
      ref={stickyRef}
    >
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={sticky ? 2 : 3}>
            <Link to={getLocalizedLink("/", siteSettings.node_locale)}>
              <img
                src={
                  sticky
                    ? siteSettings.logoSticky.file.url
                    : siteSettings.logo.file.url
                }
                className={`logo ${sticky ? "logo--sticky" : ""}`}
                alt="Logo"
              />
            </Link>
          </Col>
          {sticky && (
            <Col xs={6} className="d-flex justify-content-between">
              {siteSettings.navigation.map((navigationItem, index) => (
                <div
                  key={navigationItem.slug}
                  onMouseLeave={closeSubMenu}
                  role="menuitem"
                  tabIndex={index}
                >
                  <Link
                    to={`/${navigationItem.slug}`}
                    className={`bottom__link ${
                      hovered === index &&
                      navigationItem.childPages &&
                      navigationItem.childPages.length > 0
                        ? "bottom__link--open"
                        : isPageActive(navigationItem)
                        ? "bottom__link--active"
                        : ""
                    }`}
                    onMouseEnter={() => setHovered(index)}
                  >
                    {navigationItem.localizedPage.name}
                  </Link>
                  {navigationItem.childPages &&
                  navigationItem.childPages.length > 0
                    ? renderSubMenu(navigationItem, index, true)
                    : null}
                </div>
              ))}
            </Col>
          )}
          <Col>
            <Row className="top">
              <Col className="d-flex align-items-center justify-content-end">
                {!sticky && (
                  <>
                    <div className="top__link">
                      <FontAwesomeIcon icon={faPenSquare} className="icon" />
                      <Link
                        to={getLocalizedLink(
                          "/artikel?type=blog",
                          siteSettings.node_locale
                        )}
                      >
                        Blog
                      </Link>
                    </div>
                    <div className="top__link">
                      <FontAwesomeIcon icon={faEnvelope} className="icon" />
                      <Link
                        to={getLocalizedLink(
                          "/newsletter",
                          siteSettings.node_locale
                        )}
                      >
                        Newsletter
                      </Link>
                    </div>
                  </>
                )}

                <Button
                  text={
                    <>
                      <img
                        src="/pbf_signet@1x.svg"
                        alt="PBF logo"
                        className="pbf-logo"
                        style={{ marginRight: "6px" }}
                      />
                      Peacebuilding Forum
                    </>
                  }
                  className="top__button"
                  link="https://www.frient-peacebuilding-forum.de/"
                  target="_blank"
                />
                {sticky && (
                  <FontAwesomeIcon
                    icon={faSearch}
                    className={`icon icon__search ${
                      searchOpen ? "icon__search--open" : ""
                    }`}
                    onClick={toggleSearchOpen}
                  />
                )}
                <Link
                  to={
                    siteSettings.node_locale === "de-DE"
                      ? "#"
                      : getLocalizedCurrentLink(
                          "de-DE",
                          window.location.pathname + window.location.search
                        ) || "/"
                  }
                  className={`top__language ${
                    siteSettings.node_locale === "de-DE"
                      ? "top__language--active"
                      : ""
                  }`}
                >
                  DE
                </Link>
                <Link
                  to={
                    siteSettings.node_locale === "en"
                      ? "#"
                      : getLocalizedCurrentLink(
                          "en",
                          window.location.pathname + window.location.search
                        )
                  }
                  className={`top__language ${
                    siteSettings.node_locale === "en"
                      ? "top__language--active"
                      : ""
                  }`}
                >
                  EN
                </Link>
              </Col>
            </Row>
            {!sticky && (
              <Row className="justify-content-between bottom">
                <Col xs={9} className="d-flex justify-content-between">
                  {siteSettings.navigation.map((navigationItem, index) => (
                    <div
                      key={navigationItem.slug}
                      onMouseLeave={closeSubMenu}
                      role={"menuitem"}
                      tabIndex={index}
                    >
                      <Link
                        to={getLocalizedLink(
                          `/${navigationItem.slug}`,
                          siteSettings.node_locale
                        )}
                        className={`bottom__link ${
                          hovered === index &&
                          navigationItem.childPages &&
                          navigationItem.childPages.length > 0
                            ? "bottom__link--open"
                            : isPageActive(navigationItem)
                            ? "bottom__link--active"
                            : ""
                        }`}
                        onMouseEnter={() => setHovered(index)}
                      >
                        {navigationItem.localizedPage.name}
                      </Link>
                      {navigationItem.childPages &&
                      navigationItem.childPages.length > 0
                        ? renderSubMenu(navigationItem, index, false)
                        : null}
                    </div>
                  ))}
                </Col>
                <Col lg="auto">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className={`icon icon__search ${
                      searchOpen ? "icon__search--open" : ""
                    }`}
                    onClick={toggleSearchOpen}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <div className="sub-menu" ref={subMenuRef}>
          <Collapse in={searchOpen}>
            <div>
              <Container fluid className="sub-menu__content">
                <Row>
                  <Col>
                    <h4>{getValue("search.question", microcopy)}</h4>
                    <InputWithButton
                      buttonText={getValue("actions.search", microcopy)}
                      placeholder={getValue(
                        "placeholders.searchTerm",
                        microcopy
                      )}
                      onButtonClick={goToSearchPage}
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </Collapse>
        </div>
        {/* {siteSettings.navigation.map((navigationItem, index) => {
          return navigationItem.childPages &&
            navigationItem.childPages.length > 0 ? (
            <div className="sub-menu">
              <Collapse in={hovered === index} onMouseLeave={closeSubMenu}>
                <div>
                  <Container fluid className="sub-menu__content">
                    <Row>
                      <Col className="sub-menu__links" md={3}>
                        <span className="sub-menu-links__overview">
                          {getValue("labels.overview", microcopy)}
                        </span>

                        <div>
                          {navigationItem.childPages &&
                            navigationItem.childPages.map(item => (
                              <Link
                                to={getLocalizedLink(
                                  `/${navigationItem.slug}/${item.slug}`,
                                  siteSettings.node_locale
                                )}
                                className="sub-menu-links__link"
                              >
                                {item.localizedPage.name}
                              </Link>
                            ))}
                        </div>
                      </Col>
                      <Col md={{ span: 4, offset: 1 }}>
                        <p>
                          {navigationItem.localizedPage.shortDescription &&
                            navigationItem.localizedPage.shortDescription
                              .content[0].content[0].value}
                        </p>
                        <Button
                          className="sub-menu-see-more"
                          type="quaternary"
                          text={getValue("actions.seeMore", microcopy)}
                          link={getLocalizedLink(
                            `/${navigationItem.slug}`,
                            siteSettings.node_locale
                          )}
                        />
                      </Col>
                      <Col md={4}>
                        <Image
                          fluid={
                            navigationItem.localizedPage.image &&
                            navigationItem.localizedPage.image.imageLandscape
                              .fluid
                          }
                          style={{ width: "100%", height: "100%" }}
                          imgStyle={{ objectFit: "contain" }}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Collapse>
            </div>
          ) : null;
        })} */}
      </Container>
    </nav>
  );
};
/* PropTypes */
DesktopNav.propTypes = {};
DesktopNav.defaultProps = {};

/* Local utility functions */

/* Styles */

export default DesktopNav;
