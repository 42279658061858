/* Global imports */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getLocalizedLink } from "../helper/link";
import { dtrOptions } from "../helper/richText";
import { getValue } from "../helper/translation";
import useToggle from "../hooks/useToggle";
import Checkbox from "./checkbox";
/* Local imports */
import InputWithButton from "./InputWithButton";

/* Component definition */
const MobileFooter = ({ siteSettings }) => {
  const {
    logoMobile,
    siteDescription,
    microcopy,
    footerAddress,
  } = siteSettings;
  const [email, setEmail] = React.useState("");
  const [termsAccepted, toggleTermsAccepted] = useToggle(false);
  const [newsError, setNewsError] = React.useState(false);
  const [newsSuccesful, setnewsSuccesful] = React.useState(false);

  const setEmailValue = e => {
    setEmail(e.target.value);
  };

  const verifyEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const addEmailToList = async email => {
    try {
      if (verifyEmail(email)) {
        const response = await axios.post(
          `https://cuisines-sendinblue-api.azurewebsites.net/api/add`,
          {
            email: email,
            domain: "frient.de"
          }
        );
        setNewsError(false);
        setnewsSuccesful(true);
      } else {
        setnewsSuccesful(false);
        setNewsError(true);
      }
    } catch (error) {
      setnewsSuccesful(false);
      setNewsError(true);
    }
  };

  return (
    <footer className="mobileFooter" id="footer">
      <Container className="newsletter">
        <Row>
          <Col>
            <h4 className="title">Impulse-Newsletter</h4>
            <InputWithButton
              className="email"
              placeholder={getValue("placeholders.email", microcopy)}
              buttonText={
                <FontAwesomeIcon icon={faChevronRight} className="chevron" />
              }
              value={email}
              onChange={setEmailValue}
              onButtonClick={() => addEmailToList(email)}
              disabled={!termsAccepted || !email}
            />
            {newsError && (
              <div className="msg errorMsg">
                <small>You've searched a wrong email</small>
              </div>
            )}
            {newsSuccesful && (
              <div className="msg successMsg">
                <small>Thanks! We've received your request</small>
              </div>
            )}
            <Checkbox
              className="conditions"
              text={getValue("labels.terms", microcopy)}
              checked={termsAccepted}
              onToggle={toggleTermsAccepted}
            />
          </Col>
        </Row>
      </Container>
      <Container className="content">
        <Row>
          <Col>
            <img src={logoMobile.file.url} alt="Logo" className="logo" />
            <p className="description">{siteDescription}</p>
            {siteSettings.navigation.map(page => {
              if (page.slug === "publikationen") {
                return (
                  <div className="item" key={page.slug}>
                    <Link
                      to={getLocalizedLink(
                        `/artikel`,
                        siteSettings.node_locale
                      )}
                      className="mainLink"
                    >
                      {getValue("labels.articles", microcopy)}
                    </Link>
                  </div>
                );
              }
              return (
                <div className="item" key={page.slug}>
                  <Link
                    to={getLocalizedLink(
                      `/${page.slug}`,
                      siteSettings.node_locale
                    )}
                    className="mainLink"
                  >
                    {page.localizedPage.name}
                  </Link>
                </div>
              );
            })}
            <div className="contact item">
              <Link to="/kontakt">Kontakt</Link>
              <p className="address">
                {documentToReactComponents(footerAddress.json, dtrOptions)}
              </p>
            </div>
            <div className="copyright">
              <div className="mobSecondaryLinks">
                {/* <Link to="/login" className="small">
                  {getValue("links.login", microcopy)}
                </Link> */}
                <Link
                  to={getLocalizedLink("/kontakt", siteSettings.node_locale)}
                  className="small"
                >
                  {getValue("links.contact", microcopy)}
                </Link>
                <Link
                  to={getLocalizedLink("/jobs", siteSettings.node_locale)}
                  className="small"
                >
                  {getValue("links.jobs", microcopy)}
                </Link>
                <Link
                  to={getLocalizedLink("/impressum", siteSettings.node_locale)}
                  className="small"
                >
                  {getValue("links.imprint", microcopy)}
                </Link>
                <Link
                  to={getLocalizedLink(
                    "/datenschutz",
                    siteSettings.node_locale
                  )}
                  className="small"
                >
                  {getValue("links.dataProtection", microcopy)}
                </Link>
              </div>
              <div className="small mobFooterContentfulContainer">
                <span>© FriEnt {new Date().getFullYear()}</span>
                <a
                  href="https://www.contentful.com/"
                  rel="nofollow"
                  target="_blank"
                >
                  <img
                    src="https://images.ctfassets.net/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg"
                    style={{ maxWidth: "120px", width: "100%" }}
                    alt="Powered by Contentful"
                  />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
/* PropTypes */
MobileFooter.propTypes = {};
MobileFooter.defaultProps = {};

/* Local utility functions */

/* Styles */

export default MobileFooter;
