/* Global imports */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import axios from "axios";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Link, { getLocalizedLink } from "../helper/link";
import { dtrOptions } from "../helper/richText";
import { getValue } from "../helper/translation";
import useToggle from "../hooks/useToggle";
import Checkbox from "./checkbox";
/* Local imports */
import InputWithButton from "./InputWithButton";

/* Local imports */

/* Component definition */
const DesktopFooter = ({ siteSettings }) => {
  const {
    logoMobile,
    siteDescription,
    footerAddress,
    microcopy,
  } = siteSettings;
  const [email, setEmail] = React.useState("");
  const [termsAccepted, toggleTermsAccepted] = useToggle(false);
  const [newsError, setNewsError] = React.useState(false);
  const [newsSuccesful, setnewsSuccesful] = React.useState(false);

  const setEmailValue = e => {
    setEmail(e.target.value);
  };

  const verifyEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const addEmailToList = async email => {
    try {
      if (verifyEmail(email)) {
        const response = await axios.post(
          `https://cuisines-sendinblue-api.azurewebsites.net/api/add`,
          {
            email: email,
            domain: "frient.de"
          }
        );
        setNewsError(false);
        setnewsSuccesful(true);
      } else {
        setnewsSuccesful(false);
        setNewsError(true);
      }
    } catch (error) {
      setnewsSuccesful(false);
      setNewsError(true);
    }
  };

  return (
    <footer className="desktopFooter" id="footer">
      <div className="newsletter">
        <Container fluid>
          <h4 className="title">Impulse-Newsletter</h4>
          <InputWithButton
            className="email"
            placeholder={getValue("placeholders.email", microcopy)}
            buttonText={getValue("actions.subscribe", microcopy)}
            value={email}
            onChange={setEmailValue}
            onButtonClick={() => addEmailToList(email)}
            disabled={!termsAccepted || !email}
          />
          {newsError && (
            <div className="msg errorMsg">
              <small>You've searched a wrong email</small>
            </div>
          )}
          {newsSuccesful && (
            <div className="msg successMsg">
              <small>Thanks! We've received your request</small>
            </div>
          )}
          <Checkbox
            className="conditions"
            text={getValue("labels.terms", microcopy)}
            checked={termsAccepted}
            onToggle={toggleTermsAccepted}
          />
        </Container>
      </div>
      <div className="content">
        <Container fluid className={"px-4"}>
          <Row className="info">
            <Col md={4}>
              <img src={logoMobile.file.url} className="logo" alt="Logo" />
            </Col>
            <Col md={8}>
              <p className="description">{siteDescription}</p>
            </Col>
          </Row>
          <Row className="menu">
            <Col md={4}>
              <Link
                to={getLocalizedLink("/kontakt", siteSettings.node_locale)}
                className="mainLink"
              >
                {getValue("links.contact", microcopy)}
              </Link>
              <p className="address">
                {documentToReactComponents(footerAddress.json, dtrOptions)}
              </p>
            </Col>
            <Col>
              <Row>
                {siteSettings.navigation.map((navigationItem, index) => {
                  if (navigationItem.slug === "publikationen") {
                    return (
                      <Col
                        md={4}
                        // md={index !== 2 ? 3 : { span: 3, offset: 3 }}
                        // lg={index !== 2 ? 3 : { span: 3, offset: 0 }}
                        className="mb-md-5"
                        key="artikel"
                      >
                        <Link
                          to={getLocalizedLink(
                            `/artikel`,
                            siteSettings.node_locale
                          )}
                          className="mainLink"
                        >
                          {getValue("labels.articles", microcopy)}
                        </Link>

                        <Link
                          to={getLocalizedLink(
                            `/publikationen`,
                            siteSettings.node_locale
                          )}
                          className="subLink"
                        >
                          {getValue("labels.publications", microcopy)}
                        </Link>
                        <Link
                          to={getLocalizedLink(
                            `/artikel?type=blog`,
                            siteSettings.node_locale
                          )}
                          className="subLink"
                        >
                          {getValue("labels.blog", microcopy)}
                        </Link>
                        <Link
                          to={getLocalizedLink(
                            `/artikel?type=impuls`,
                            siteSettings.node_locale
                          )}
                          className="subLink"
                        >
                          {getValue("labels.impulseArticle", microcopy)}
                        </Link>
                      </Col>
                    );
                  }
                  return (
                    <Col
                      md={4}
                      // md={index !== 2 ? 3 : { span: 3, offset: 3 }}
                      // lg={index !== 2 ? 3 : { span: 3, offset: 0 }}
                      className="mb-md-5"
                      key={navigationItem.slug}
                    >
                      <Link
                        to={getLocalizedLink(
                          `/${navigationItem.slug}`,
                          siteSettings.node_locale
                        )}
                        className="mainLink"
                      >
                        {navigationItem.localizedPage.name}
                      </Link>
                      {navigationItem.childPages &&
                        navigationItem.childPages.map(item => (
                          <Link
                            key={item.slug}
                            to={getLocalizedLink(
                              `/${navigationItem.slug}/${item.slug}`,
                              siteSettings.node_locale
                            )}
                            className="subLink"
                          >
                            {item.localizedPage.name}
                          </Link>
                        ))}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-between copyright">
            <Col md="auto" className="small">
              <div className="footerContentfulLogoContainer">
                <span> © FriEnt {new Date().getFullYear()}</span>
                <a
                  href="https://www.contentful.com/"
                  rel="nofollow"
                  target="_blank"
                >
                  <img
                    src="https://images.ctfassets.net/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg"
                    style={{ maxWidth: "120px", width: "100%" }}
                    alt="Powered by Contentful"
                  />
                </a>
              </div>
            </Col>
            <Col className="d-flex justify-content-end secondaryLinks">
              {/* <Link to="/login" className="small">
                {getValue("links.login", microcopy)}
              </Link> */}
              <Link
                to={getLocalizedLink("/kontakt", siteSettings.node_locale)}
                className="small"
              >
                {getValue("links.contact", microcopy)}
              </Link>
              <Link
                to={getLocalizedLink("/jobs", siteSettings.node_locale)}
                className="small"
              >
                {getValue("links.jobs", microcopy)}
              </Link>
              <Link
                to={getLocalizedLink("/impressum", siteSettings.node_locale)}
                className="small"
              >
                {getValue("links.imprint", microcopy)}
              </Link>
              <Link
                to={getLocalizedLink("/datenschutz", siteSettings.node_locale)}
                className="small"
              >
                {getValue("links.dataProtection", microcopy)}
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};
/* PropTypes */
DesktopFooter.propTypes = {};
DesktopFooter.defaultProps = {};

/* Local utility functions */

/* Styles */

export default DesktopFooter;
