import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import React from "react";

export const dtrOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const type = node.data.target.fields.file.contentType.split("/")[0];
      return type === "image" ? (
        <figure style={{ display: "inline-block" }}>
          <img
            style={{ maxWidth: "100%" }}
            src={node.data.target.fields.file.url}
            alt={node.data.target.fields.description}
          />
          <figcaption style={{ textAlign: "right" }}>
            {node.data.target.fields.description}
          </figcaption>
        </figure>
      ) : null;
    },
    [INLINES.ASSET_HYPERLINK]: node => {
      return (
        <a href={node.data.target.fields.file.url}>{node.content[0].value}</a>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: node => {
      // Not supported
      return null;
    },
  },
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
};
